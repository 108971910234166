import React from "react";
import csaba from "./csaba.jpeg";

function Testimonials() {
    return (
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pb-16 pt-10 text-center mt-12">
            <div className="bg-[#f6f6f6] rounded-lg p-4 shadow-[4px_4px_0px_0px_black]">
                <p class="mx-auto mt-6 max-w-5xl lg:text-3xl md:text-2xl sm:text-xl text-xl text-black">
                    "Pratham proved he knows what a social media profile should look like. He supported me immensely
                    from the start and gave me unique tips on how to increase my engagement and profile visits on
                    Twitter/X."
                </p>
                <div className="flex items-center gap-4 mt-12 justify-center p-4">
                    <div className="flex gap-8">
                        <img
                            className="lg:w-24 lg:h-24 md:w-18 md:h-18 sm:w-12 sm:h-12 w-12 h-12 rounded-full"
                            src={csaba}
                            alt="Csaba Kissi"
                        />
                        <div className="flex flex-col tracking-wider">
                            <label className="font-bold text-left text-2xl">Csaba Kissi</label>
                            <label className="font-bold text-left">&#9733;&#9733;&#9733;&#9733;&#9733;</label>
                            <label className="font-bold text-left">
                                Solopreneur, Social Media Influencer, 25+ years experience.
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Testimonials;
