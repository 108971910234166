import doc from "./doc.png";
import video from "./video.png";

function Callout() {
    return (
        <div className="mx-auto max-w-[1300px] px-4 sm:px-6 lg:px-8 pb-16 pt-10 text-center">
            <h1 class="mx-auto font-['poppins'] font-black lg:text-9xl md:text-8xl text-7xl text-black">
                Don't Wait, Get Roasted Today!!!
            </h1>
            <div class="mt-10 flex justify-center gap-x-6">
                <a
                    class="group inline-flex items-center justify-center rounded-xl py-4 px-8 text-2xl font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-yellow-500 border-4 border-black shadow-[10px_10px_0px_0px_black] text-white hover:shadow-[0px_0px_0px_0px_black] transition duration-150 ease-in active:bg-slate-800 active:text-white focus-visible:outline-slate-900"
                    variant="solid"
                    href="https://sketchnotes.lemonsqueezy.com/checkout/buy/200be104-2abf-4e5e-bd4d-b0d9438c8c29"
                >
                    Get your social profiles roasted - $99 only.
                </a>
            </div>
        </div>
    );
}

export default Callout;
