import doc from "./doc.png";
import video from "./video.png";
import brand from "./brand.png";
import career from "./career.png";
import knowledge from "./knowledge.png";
import visibility from "./visibility.png";
import learning from "./learning.png";
import job from "./job.png";
import professional from "./professional.png";
import global from "./global.png";
import feedback from "./feedback.png";
import advocacy from "./advocacy.png";

function Care() {
    return (
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pb-16 pt-10 text-center mt-12">
            <h1 class="mx-auto font-['poppins'] font-black lg:text-9xl md:text-8xl text-7xl text-black">
                Why You Should Care!
            </h1>
            <p class="mx-auto mt-6 max-w-5xl text-3xl font-bold text-black font-['poppins']">
                Having a strong online presence makes you stand out! In Every Aspect!!
            </p>
            <div className="flex mt-12 gap-4 flex-wrap justify-center">
                <div className="border-4 border-black rounded-lg p-4 w-full lg:max-w-sm md:max-w-sm sm:w-full">
                    <img src={brand} className="w-12 mb-4" alt="" />
                    <p className="font-bold text-2xl text-left mb-2 font-['Poppins']">Personal Branding</p>
                    <p className="font-semibold	text-left text-xl">
                        A strong social media presence helps individuals showcase their skills, expertise, and
                        accomplishments, contributing to a strong professional brand.
                    </p>
                </div>
                <div className="border-4 border-black rounded-lg p-4 w-full lg:max-w-sm md:max-w-sm sm:w-full">
                    <img src={career} className="w-12 mb-4" alt="" />
                    <p className="font-bold text-2xl text-left mb-2 font-['Poppins']">Career Opportunities</p>
                    <p className="font-semibold	text-left text-xl">
                        Social media can serve as a networking tool, connecting individuals with potential employers,
                        mentors, and colleagues, opening up new career opportunities.
                    </p>
                </div>
                <div className="border-4 border-black rounded-lg p-4 w-full lg:max-w-sm md:max-w-sm sm:w-full">
                    <img src={knowledge} className="w-12 mb-4" alt="" />
                    <p className="font-bold text-2xl text-left mb-2 font-['Poppins']">Knowledge Sharing</p>
                    <p className="font-semibold	text-left text-xl">
                        Individuals can leverage social platforms to share industry insights, trends, and knowledge,
                        positioning themselves as thought leaders in their field.
                    </p>
                </div>
                <div className="border-4 border-black rounded-lg p-4 w-full lg:max-w-sm md:max-w-sm sm:w-full">
                    <img src={visibility} className="w-12 mb-4" alt="" />
                    <p className="font-bold text-2xl text-left mb-2 font-['Poppins']">Personal Brand Visibility</p>
                    <p className="font-semibold	text-left text-xl">
                        Building a personal brand on social media increases visibility, making it easier for others to
                        find and recognize your unique skills and contributions.
                    </p>
                </div>
                <div className="border-4 border-black rounded-lg p-4 w-full lg:max-w-sm md:max-w-sm sm:w-full">
                    <img src={learning} className="w-12 mb-4" alt="" />
                    <p className="font-bold text-2xl text-left mb-2 font-['Poppins']">Continuous Learning</p>
                    <p className="font-semibold	text-left text-xl">
                        Following industry leaders and engaging in relevant discussions on social media platforms allows
                        individuals to stay updated on the latest trends and advancements in their field.
                    </p>
                </div>
                <div className="border-4 border-black rounded-lg p-4 w-full lg:max-w-sm md:max-w-sm sm:w-full">
                    <img src={job} className="w-12 mb-4" alt="" />
                    <p className="font-bold text-2xl text-left mb-2 font-['Poppins']">Job Search and Recruitment</p>
                    <p className="font-semibold	text-left text-xl">
                        Social media is a valuable resource for job seekers and recruiters alike, providing a platform
                        for showcasing qualifications, skills, and job opportunities.
                    </p>
                </div>
                <div className="border-4 border-black rounded-lg p-4 w-full lg:max-w-sm md:max-w-sm sm:w-full">
                    <img src={professional} className="w-12 mb-4" alt="" />
                    <p className="font-bold text-2xl text-left mb-2 font-['Poppins']">Professional Development</p>
                    <p className="font-semibold	text-left text-xl">
                        Participating in industry-specific groups and discussions on social media can contribute to
                        ongoing professional development by fostering learning and collaboration.
                    </p>
                </div>
                <div className="border-4 border-black rounded-lg p-4 w-full lg:max-w-sm md:max-w-sm sm:w-full">
                    <img src={global} className="w-12 mb-4" alt="" />
                    <p className="font-bold text-2xl text-left mb-2 font-['Poppins']">Global Networking</p>
                    <p className="font-semibold	text-left text-xl">
                        Social media transcends geographical boundaries, enabling individuals to connect with
                        professionals worldwide and broaden their network beyond local constraints.
                    </p>
                </div>
                <div className="border-4 border-black rounded-lg p-4 w-full lg:max-w-sm md:max-w-sm sm:w-full">
                    <img src={feedback} className="w-12 mb-4" alt="" />
                    <p className="font-bold text-2xl text-left mb-2 font-['Poppins']">Feedback and Improvement</p>
                    <p className="font-semibold	text-left text-xl">
                        Social media allows individuals to receive feedback on their work, ideas, and projects,
                        providing valuable insights for continuous improvement.
                    </p>
                </div>
                <div className="border-4 border-black rounded-lg p-4 w-full lg:max-w-sm md:max-w-sm sm:w-full">
                    <img src={advocacy} className="w-12 mb-4" alt="" />
                    <p className="font-bold text-2xl text-left mb-2 font-['Poppins']">Personal Brand Advocacy</p>
                    <p className="font-semibold	text-left text-xl">
                        A strong social media presence encourages others to advocate for your personal brand,
                        potentially leading to recommendations, collaborations, and increased visibility within your
                        professional community.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Care;
