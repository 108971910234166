function Footer() {
    return (
        <footer class="container mx-auto my-5 flex h-16 flex-col items-center justify-between space-y-3 border-t border-black px-3 pt-4 text-center sm:h-20 sm:flex-row sm:pt-2 md:text-lg">
            <div>
                Built by{" "}
                <a href="https://twitter.com/prathkum" target="_blank" class="font-bold transition hover:text-black/50">
                    Pratham{" "}
                </a>
                with love.
            </div>
            <div class="flex space-x-4 pb-4 sm:pb-0 text-black">
                <a class="group" aria-label="X" href="https://twitter.com/prathkum">
                    Twitter
                </a>
                <a class="group" aria-label="LinkedIn" href="https://linkedin.com/in/prathkum">
                    LinkedIn
                </a>
            </div>
        </footer>
    );
}

export default Footer;
