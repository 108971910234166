import doc from "./doc.png";
import video from "./video.png";

function Delivery() {
    return (
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pb-16 pt-10 text-center mt-12">
            <h1 class="mx-auto font-['poppins'] font-black lg:text-9xl md:text-8xl text-7xl text-black">
                What Will You Get!
            </h1>
            <p class="mx-auto mt-6 max-w-5xl text-3xl font-bold text-black font-['poppins']">
                Depending on the traffic, current delivery time is 24 hours.
            </p>
            <div className="flex mt-12 gap-4 flex-wrap lg:flex-nowrap md:flex-nowrap sm:flex-wrap">
                <div className="border-4 border-black p-6 rounded-lg">
                    <img src={video} alt="Video icon" />
                    <p className="font-bold mt-6 text-2xl text-left mb-6">15 minutes recorded video.</p>
                    <p className="font-semibold	text-left text-xl">
                        I am going to take a close look at your social media profile(s) and provide you a constructive
                        feedback and actionable insights that are tailored to elevate your online presence. I'll discuss
                        strategies to optimize your bio for impact, clarity, and discoverability. Dive into content
                        suggestions that will not only engage your audience but also set you apart from the rest.
                    </p>
                </div>
                <div className="border-4 border-black p-6 rounded-lg">
                    <img src={doc} alt="Doc icon" />
                    <p className="font-bold mt-6 text-2xl text-left mb-6">One-pager document with Action items.</p>
                    <p className="font-semibold	text-left text-xl">
                        This one-pager document is your roadmap to implementing the personalized recommendations from
                        our 'Your Personalized Social Media Profile Roast' video. Not only action items, Additionally, I
                        will provide you a thorough understanding of the kinds of content you may publish that will
                        appeal to the platform's algorithm as well as the audience you are trying to reach. And much
                        more...
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Delivery;
