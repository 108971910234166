import React, { useState } from "react";

const FAQ = () => {
    const faqData = [
        {
            question: "What is this?",
            answer: "This is 'Roast My Social Media Profile' as a Service platform.  I give you constructive criticism so you may develop a strong online presence and enhance your social media profile.",
        },
        {
            question: "What will I get for $99?",
            answer: "I'll look over your social media profiles in a 15-minute video recording and provide you with tips on how to make them stand out. In addition, you'll receive a one-pager that has all the action items and details you need to take your profile to the next level.",
        },
        {
            question: "What do I need to do?",
            answer: "Simply click on the button above, complete the payment, fill the form with all the necessary details, and I'll deliver the things within 48 hours.",
        },
        {
            question: "Why delivery time is 48 hours?",
            answer: "Video recording and one-pager will be manually crafter by me which can take time. Also, I want to make sure to provide the best possible feedback. Haste makes waste!!",
        },
        {
            question: "Why this service is for me?",
            answer: "I built a huge online presence of over 500,000 audience size over the years. So far I have helped over 100 people to build a strong online presence that's why I started this service.",
        },
        {
            question: "I'm a student; is this worthwhile for me?",
            answer: "A student can benefit much from having a strong internet presence. Job offers, networking, and recognition may all help you advance in your career.",
        },
    ];

    const [expandedIndex, setExpandedIndex] = useState(null);

    const handleToggle = (index) => {
        setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return (
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pb-16 pt-10 mt-12">
            <h1 class="mx-auto font-black lg:text-9xl md:text-8xl text-7xl text-black text-center">
                Frequently Asked Questions!
            </h1>
            <div className="max-w-2xl mx-auto mt-8 p-4 font-black">
                {faqData.map((item, index) => (
                    <div key={index} className="mb-4 border-4 border-black rounded-lg p-4">
                        <div
                            className="flex justify-between items-center cursor-pointer"
                            onClick={() => handleToggle(index)}
                        >
                            <h2 className="text-2xl font-['poppins']">{item.question}</h2>
                            <span className="text-2xl">{expandedIndex === index ? "-" : "+"}</span>
                        </div>
                        {expandedIndex === index && <div className="mt-6 text-xl">{item.answer}</div>}
                    </div>
                ))}
                <p>
                    Have more questions? Feel free to{" "}
                    <a className="underline" href="https://twitter.com/prathkum">
                        reach me out!
                    </a>
                </p>
            </div>
        </div>
    );
};

export default FAQ;
