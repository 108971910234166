import Testimonials from "./Testimonials";
import Care from "./Care";
import Delivery from "./Delivery";
import FAQ from "./FAQ";
import Callout from "./Callout";
import Footer from "./Footer";

function App() {
    return (
        <>
            <div class="flex items-center justify-center flex-col md:flex-row py-3 w-full bg-red-100 text-sm md:text-base font-medium border-b border-red-200 text-red-950 sticky top-0 left-0 z-[9999] shadow-xl shadow-red-400/5">
                🤯 Hurry up! Be in the first 10 customers and get 20% off.
                <a
                    href="https://sketchnotes.lemonsqueezy.com/checkout/buy/200be104-2abf-4e5e-bd4d-b0d9438c8c29?checkout[discount_code]=FIRST10"
                    target="_blank"
                    class="md:ml-2 mt-1 md:mt-0"
                >
                    <button
                        role="link"
                        class="relative bg-[linear-gradient(#EF4344,#EF4344),linear-gradient(#3b82f6,#3b82f6)] bg-[length:100%_2px,0_2px] bg-[position:100%_100%,0_100%] bg-no-repeat text-red-950 transition-[background-size,color] duration-500 hover:bg-[0_2px,100%_2px] hover:text-[#3b82f6]"
                    >
                        I want to improve my social presence.
                    </button>
                </a>
            </div>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pb-16 pt-10 text-center">
                <h1 class="mx-auto font-['poppins'] font-black lg:text-9xl md:text-8xl text-7xl text-black">
                    From Roasted to Boasted: <span>Grow Your Social Media.</span>
                </h1>
                <p class="mx-auto mt-6 max-w-5xl text-3xl font-bold text-black font-['poppins']">
                    So far, I have helped over 100 people building a strong online social media presence.
                </p>
                <div class="mt-10 flex justify-center gap-x-6">
                    <a
                        class="cursor-pointer group inline-flex items-center justify-center rounded-xl py-4 px-8 text-2xl font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-yellow-500 border-4 border-black shadow-[10px_10px_0px_0px_black] text-white hover:shadow-[0px_0px_0px_0px_black] transition duration-150 ease-in active:bg-slate-800 active:text-white focus-visible:outline-slate-900"
                        variant="solid"
                        href="https://sketchnotes.lemonsqueezy.com/checkout/buy/200be104-2abf-4e5e-bd4d-b0d9438c8c29"
                    >
                        Get your social profiles roasted - $99 only.
                    </a>
                </div>
            </div>
            <Testimonials />
            <Care />
            <Delivery />
            <FAQ />
            <Callout />
            <Footer />
        </>
    );
}

export default App;
